// 全局状态树
import { createUserPreference, createUserState } from './types'
import { editZendeskDom } from '~/utils/zendesk.js'
import { getContractList, getSystemNotice, getPushCount } from '~/server/common'
import { getCurrentSymbol } from '~/server/copy_trading_mix'
import { getMcpContractList } from '~/server/mcp'
import { locales, SUPPORTED_THEME } from 'bgconfig'
import { state as FutureState, getters as FutureGetters, mutations as FutureMutations, actions as FutureActions } from './future'
import { state as NewTradingState, getters as NewTradingGetters, mutations as NewTradingMutations, actions as NewTradingActions } from './newTrading'
import { state as TradeState, mutations as TradeMutations, getters as TradeGetters, actions as TradeActions } from './trade'
import { switchDeduct, getSpotCoinChainList } from '~/server/assetv1/'
import { usdtRate, getCoinList, setUserPreference, getUserPreference, getDelegateFlag, setDelegateFlag } from '../server/common/index'
import asset from './asset'
import currencyConfig from '~/config/currencyConfig.json';
import HMM from '~/web-base/components/HandmadeMessage/index.js'
import LocalCache, { CacheKey } from '~/utils/LocalCache'
import LocalCookie, { CookieKey } from '~/utils/localCookie.js'
import spot from './spot'
import trace from './trace'
import tracePro from './tracePro'
import Vuex from 'vuex'
import TokenCookie, {TokenKey} from "~/utils/TokenCookie";
import { logoutZendesk, autoLoginZendesk } from '~/utils/utils.js';


require('@babel/polyfill')

const zendeskLauncherParamsDefault = {
  width: '44px',
  height: '44px',
  position: {
    top: '',
    right: '22px',
    bottom: '40px',
    left: ''
  },
  icon: '/assets/images/new_index/icon_service1.svg',
  hoverIcon: '/assets/images/new_index/icon_service1.svg'
}

const store = () =>
  new Vuex.Store({
    state: {
      usdtRate: {
        USDT_CNY: '0',
        USDT_EUR: '0',
        USDT_JPY: '0',
        USDT_KRW: '0',
        USDT_RUB: '0',
        USDT_TWD: '0',
        USDT_VND: '0'
      }, // 货币汇率
      symbolList:[],// 货币符号
      allCoinConfigList: [], // 全币种配置列表 包含 icon 可划转 可充提
      allCoinList: [], // 全部币种列表 包含 coinName coinId tagStatus sortId
      allContractList: [],       //全部合约列表信息
      allMcpContractList: [], // 混合合约列表
      allSupportCopyContractList: [], // 支持跟单的混合币对列表
      apiDomains: null,
      appInfo:{ appVersion: '', uid: '', uToken: undefined },
      arbitrageUserLockTime: { arbitrageIntervalTime: 0, refreshTime: 0 }, // 套利用户锁定及检测时间
      auditInfo: null, // 審計資訊
      canLoad: true,
      contractAndFollowSwitch: { followSwitch: false, contractSwitch: false },       // 老合约 老跟单 展示开关
      device: 'web',
      domain: {},
      domains: [],
      inviteCodeInfo: {},
      isAgent: false,
      isListeningZendesk: false,
      layout: 'center',
      leftCoinList: [], // 左对币种
      locale: 'zh-CN',
      locales: locales,
      loginlastTime: '',
      mixTraderNewTips: false, // 合约跟单提醒
      moneyType: null,
      newTheme: 'black', // black:黑色，white:白色
      noticeData: {},
      noticeInfo: {},
      rightCoinList: [], // 右对币种
      shareUrl: '',
      singleCurrency: false,
      theme: SUPPORTED_THEME[0], // 0:黑色，1:白色
      tokenInfo: null,
      totalAsset: { totalBTC: 0, totalUSDT: 0, contractBTC: 0, contractUSDT: 0, otcBTC: 0, otcUSDT: 0, spotBTC: 0, spotUSDT: 0, mcpBTC: 0, mcpUSDT: 0, newContractBTC: 0, newContractUSDT: 0, },
      traceStateNum: 1,       //跟单数据准备状态 1 初始化  2 合约列表获取完成
      traceStateNumMix: 1,
      traderNewTips: false, // 跟单提醒
      units: Object.keys(currencyConfig).filter(key => currencyConfig[key].enabled),
      unreadCount: 0,
      user: createUserState(),
      userPreference: createUserPreference(), // 新增用户偏好设置
      userPreferenceReady: false,
      userReady: false,
      userTopExchange: '',
      uToken: '',
      withdrawResult: null, // 提币成功后保存的数据,在提币结果页使用展示
      wsDomains: null,
      zendeskLauncherParams: zendeskLauncherParamsDefault,
    },
    mutations: {
      setLoginLastTime(state, status) {
        state.loginlastTime = status;
        console.log('index109', status);
      },
      getNewLoginLastTime(state) {
        return state.loginlastTime;
      },
      syncLocalStorage(state) {
        state.theme = SUPPORTED_THEME[0]
        // state.theme = LocalCookie.get(CookieKey.GLOBAL_THEME) || SUPPORTED_THEME[0]
        state.layout = LocalCache.get(CacheKey.GLOBAL_LAYOUT) || 'center'
        state.spot.layout = LocalCache.get(CacheKey.SPOT_LAYOUT) || 'center'
      },
      SET_USER(state, param) {
        param = param || {}
        if(param.userId && state.user.userId !== param.userId){
          window?.$nuxt?.$sensors?.loginWithKey('$identity_login_id', param.userId)
          TokenCookie.set(TokenKey.USER_ID, param.userId)
        }
        state.user = Object.assign({}, state.user, param)
        state.userReady = true
        autoLoginZendesk(state.user);

      },
      SET_USER_PREFERENCE(state, param) {
        param = param || {}
        state.userPreference = Object.assign({}, state.userPreference, param)
        state.userPreferenceReady = true
      },
      CLEAR_USER(state) {
        logoutZendesk();
        state.user = createUserState()
        state.userPreference = createUserPreference()
        state.userPreferenceReady = false
      },
      forcedUserOtcOpen(state) {
        state.user.openOtc = true
      },
      forcedUserContractOpen(state) {
        // 强制修改用户信息中的合约开关状态 --2019年02月26日
        state.user.openContract = true
      },
      SET_LANG(state, locale) {
        if (state.locales.indexOf(locale) !== -1) {
          state.locale = locale
          if (process.browser) {
            LocalCookie.set(CookieKey.LANG, locale)
          }
        }
      },
      setMoneyType(state, type) {
        state.moneyType = type
        // 存入到cookie中
        if (process.browser) {
          LocalCookie.set(CookieKey.UNIT, type)
        }
      },
      userTopExchange(state, userTopExchange) {
        state.userTopExchange = userTopExchange
      },
      // 从ws中取得汇率，单独设置
      SET_USDTRATE(state, rates) {
       const usdtRate = Object.keys(rates).reduce((item, key) => {
          item[key.toUpperCase()] = rates[key];
          return item;
        }, {});
        state.usdtRate = {
          ...state.usdtRate,
          ...usdtRate
        }
      },
     // 设置货币的单位、符号、汇率、
      SET_usdtToCNY(state, res) {
        // 此处需注意返回字段变化
        if (res && res.data) {
          const { country = state.units, rates = {}, supported = [] } = res.data;
          // 存储货币单位
          state.units = Object.values(country).map(item => item.toUpperCase());
          //存储汇率
          state.usdtRate = Object.keys(rates).reduce((item, key) => {
            item[key.toUpperCase()] = rates[key];
            return item;
          }, {});
          // 存储货币符号
          state.symbolList = supported.map(item => {
            return {
              ...item,
              name: item.name.toUpperCase()
            };
          });
        }
      },
      // 设置左右币列表
      setCoinList(state, payload) {
        state[payload.type].push(...payload.data)
      },
      // 设置全部币种配置列表
      setAllCoinConfigList(state, data) {
        // 对数据进行处理 在根 设置默认的chainCoinId chainCoinName
        // 并且对 链中的  充提 状态 提到 根层
        let newdata = data.map(item => {
          if (item.spotCoinChainResultList && item.spotCoinChainResultList.length > 0) {
            let result = item.spotCoinChainResultList[0]
            item.chainCoinId = result.chainCoinId
            item.chainCoinName = result.chainCoinName
            item.rechargeable = item.spotCoinChainResultList.some(i => !!i.rechargeable)
            item.withdrawable = item.spotCoinChainResultList.some(i => !!i.withdrawable)
          } else {
            item.chainCoinId = 0
            item.chainCoinName = null
            item.rechargeable = false
            item.withdrawable = false
          }
          return item
        })
        state.allCoinConfigList = newdata
      },
      // 提币结果
      setWithdrawResult(state, obj) {
        state.withdrawResult = obj
      },
      // 清空提币结果
      clearWithdrawResult(state) {
        state.withdrawResult = null
      },
      changeGtFeeSwitch(state, newValue) {
        state.user.openGtFees = newValue
      },
      UPDATE_TOKEN_INFO(state, tokenInfo) {
        state.tokenInfo = tokenInfo
      },
      // 设置全局的 合约列表信息
      SET_All_ContractList(state, data) {
        state.allContractList = data
      },

      SET_ALL_MCP_CONTRACT_LIST(state, data) {
        state.allMcpContractList = data
      },

      SET_SUPPORT_COPY_CONTRACT_LIST(state, data) {
        state.allSupportCopyContractList = data || []
      },

      /**
       * 设置跟单数据状态
       */
      SET_TRACE_STATE_NUM(state, { type }) {
        if (type === 'clear') {
          state.traceStateNum = 1
          return
        }
        if (type === 'add') {
          state.traceStateNum++
          return
        }
      },
      SET_TRACE_STATE_NUM_MIX(state, { type }) {
        if (type === 'clear') {
          state.traceStateNumMix = 1
          return
        }
        if (type === 'add') {
          state.traceStateNumMix++
          return
        }
      },
      SET_TRADER_NEW_TIPS(state, val) {
        state.traderNewTips = val || false
      },

      SET_MIX_TRADER_NEW_TIPS(state, val) {
        state.mixTraderNewTips = val || false
      },
      /**
       * 更新总资产数据
       */
      UPDATA_TOTAL_ASSET(state, obj) {
        state.totalAsset = Object.assign({}, state.totalAsset, obj)
      },
      /**
       * 设置主题
       */
      SET_THEME(state) {
        state.theme = state.theme === SUPPORTED_THEME[0] ? SUPPORTED_THEME[1] : SUPPORTED_THEME[0]
        // state.theme = "white"; //強制設置白色
        // LocalCookie.set(CookieKey.GLOBAL_THEME, state.theme)
      },
      /**
       * 设置主题 - 新
       */
      SET_THEME_NEW(state, type) {
        state.newTheme = type;
        state.theme = state.newTheme === SUPPORTED_THEME[0] ? SUPPORTED_THEME[1] : SUPPORTED_THEME[0]
        // LocalCookie.set(CookieKey.GLOBAL_THEME_NEW, state.newTheme)
      },
      SET_LAYOUT(state, layout) {
        if (layout) {
          state.layout = layout
          LocalCache.set(CacheKey.GLOBAL_LAYOUT, layout)
        } else {
          state.layout = LocalCache.get(CacheKey.GLOBAL_LAYOUT)
        }
      },
      SET_CONTRACT_FOLLOW_SWITCH(state, data) {
        state.contractAndFollowSwitch = {
          ...state.contractAndFollowSwitch,
          ...data
        }
      },
      SET_NOTICE_DATA(state, data) {
        state.noticeData = data;
      },
      SET_UNREAD_COUNT(state, data) {
        state.unreadCount = data;
      },
      SET_NOTICE_INFO(state, data) {
        state.noticeInfo = data;
      },
      SET_CAN_LOAD(state, data) {
        state.canLoad = data;
      },
      SET_IS_LISTENING_ZENDESK(state, data) {
        state.isListeningZendesk = data;
      },
      SET_ZENDESK_LAUNCHER_PARAMS(state, data) { // 定义zendesk图标信息
        let params = Object.assign({}, state.zendeskLauncherParams, data);
        state.zendeskLauncherParams = params;
        editZendeskDom();
      },
      SET_ZENDESK_LAUNCHER_DEFAULT(state) { // 还原zendesk图标信息
        state.zendeskLauncherParams = zendeskLauncherParamsDefault;
        editZendeskDom();
      },
      // 设置套利用户锁定及检测时间
      SET_ARBITRAGE_USER_LOCK_TIME(state, data) {
        state.arbitrageUserLockTime = data
      },
      SET_UTOKEN(state, data) {
        state.uToken = data;
      },
      SET_INVITE_CODE_INFO(state, inviteCodeInfo) {
        state.inviteCodeInfo = inviteCodeInfo;
      },
      SET_AGENT(state, data) {
        state.isAgent = data;
      },
      SET_DEVICE(state, data) {
        state.device = data;
      },
      SET_API_DOMAINS(state, data) {
        state.apiDomains = data;
      },
      SET_WS_DOMAINS(state, data) {
        state.wsDomains = data;
      },
      SET_CURRENCY(state, data) {
        state.singleCurrency = data;
      },
      SET_SHAREURL(state, data) {
        state.shareUrl = data;
      },
      SET_DOMAIN(state, data) {
        state.domain = data;
      },
      SET_DOMAINS(state, data) {
        console.log('domains', data)
        state.domains = data;
      },
      SET_APPINFO(state, data) {
        state.appInfo = data;
      },
      SET_AUDIT_DATA(state, data) {
        state.auditInfo = data;
      }
    },
    getters: {
      isLogin({ user,appInfo }) {
        return !!user.loginName || !!appInfo.uToken
      },
      loginName({ user }) {
        return user.loginName
      },
      // 币种转换 当前货币
      toCNYByUSDT: state => (usdt = 0) => {
        if(state.moneyType){
          const key = `USDT_${state.moneyType}`;
          const usdtToCoin = state.usdtRate[key] || 1;
          return usdtToCoin * usdt;
        }
        return usdt
      },
      // 获取当前汇率
      currentExchangeRate(state) {
        const key = `USDT_${state.moneyType}`;
        return state.usdtRate[key] || 1
      },
      /**
       * 获取当前币种符号
       * @param moneyType
       * @return {string}
       */
      currencySymbol({ moneyType,symbolList }) {
        const curObj = symbolList.find(item=>item.name == moneyType)
        return curObj?.symbol || '$';
      },
      localeDir({ locale }) {
        return locale === 'zh-CN' ? '' : '/' + locale
      },
      getLang(state) {
        return state.locale
      },
      /**
       * 获取app下的语言参数
       * @param {} state
       */
      appLang({ locale }) {
        switch (locale) {
          case 'zh-CN':
            return 'zh_CN'
          case 'en':
            return 'en_US'
          case 'ja':
            return 'ja_JP'
          case 'ko':
            return 'ko_KR'
          case 'vi':
            return 'vi_VN'
          case 'zh-TW':
            return 'zh_TW'
          case 'zh_TW': // 不确定 app 来的是到底是 zh-TW 还是 zh_TW
            return 'zh_TW'
          case 'ru':
            return 'ru_RU'
          case 'es':
            return 'es_ES'
          default:
            return 'en_US'
        }
      },
      isCN(state) {
        return state.locale == 'zh-CN'
      },
      /**
       * 查询时的所有币种
       * @param {}} state
       */
      searchAllCoinConfigList(state) {
        let list = JSON.parse(JSON.stringify(state.allCoinConfigList))
        return list
      },
      /**
       * 获取usdt链信息
       * @param {*} state
       */
      usdtChainList(state) {
        let list = state.allCoinConfigList.slice()
        let usdtobj = list.find(item => item.coinId === 2)
        if (usdtobj) {
          return usdtobj.spotCoinChainResultList || []
        }
        return []
      },
      /**
       *  根据币种id 来获取币种icon
       */
      getCoinIcon: state => coinId => {
        let coin = state.allCoinConfigList.find(item => {
          return item.coinId === coinId
        })
        if (coin) {
          if (coin.imgUrl !== '' || coin.imgUrl !== "undefined") {
            return coin.imgUrl;
          } else {
            if (coin.coinName === 'DOGE' || coin.coinName === 'DOT' || coin.coinName === 'UNI' || coin.coinName === 'EOS') {
              return `/assets/images/coin/${coin.coinName}.png`;
            }
            return `/assets/images/coin/${coin.coinName}.svg`;
          }
        }
      },

      GETContractInfo(state) {
        return productCode => {
          if (state.allContractList.length > 0) {
            let contractInfo = state.allContractList.find(item => item.productCode === productCode)
            if (contractInfo) {
              return contractInfo
            }
            return {}
          }
          return {}
        }
      },
      getCoinIdByCoinName(state) {
        return coinName => {
          if (state.allCoinList.length > 0) {
            let coin = state.allCoinList.find(item => item.coinName.toUpperCase() === coinName)
            if (coin) {
              return coin.coinId
            }
            return 0
          }
        }
      },
      getMcpContractInfo(state) {
        return symbolCode => {
          if (state.allMcpContractList.length) {
            let contractInfo = state.allMcpContractList.find(item => item.symbolCode === symbolCode)
            return contractInfo || {}
          }
          return {}
        }
      },
    },
    actions: {
      async nuxtServerInit({ commit }, { req, redirect }) {
        // console.log('WEEX nuxtServerInit')
      },
      async SET_usdtToCNY(context) {
        context.commit('SET_usdtToCNY', await usdtRate())
      },
      updatedLoginLastTime(context, status) {
        console.log('495',status);
        context.commit('setLoginLastTime', status); // mutation 中定義方法
      },
      /**
       * 获取左右对列表 老的列表
       * @param {*} param0
       */
      getCoinList({ state, commit }) {
        getCoinList().then(data => {
          if (data.code === '00000') {
            if (data.data.leftCoinList) {
              commit('setCoinList', {
                type: 'leftCoinList',
                data: data.data.leftCoinList
              })
            }
            if (data.data.rightCoinList) {
              commit('setCoinList', {
                type: 'rightCoinList',
                data: data.data.rightCoinList
              })
            }
            if (data.data.allCoinList) {
              commit('setCoinList', {
                type: 'allCoinList',
                data: data.data.allCoinList
              })
            }
          }
        })
      },

      /**
       * bft 抵扣开关
       * @param {*} param0
       * @param {*} newValue
       */
      changeGtFeeSwitch({ state, commit }, newValue) {
        switchDeduct({ deduct: Number(newValue) }).then(res => {
          if (res.code === '00000') {
            commit('changeGtFeeSwitch', newValue)
          } else {
            HMM(res.msg)
          }
        })
      },
      async getAllCoinConfigList({ commit }) {
        let result = await getSpotCoinChainList().catch(() => { })
        if (result && +result.code == 0) {
          commit('setAllCoinConfigList', result.data)
        }
      },
      async getAllContractList({ commit }) {
        let data = await getContractList()
        if (+data.code == 0 && data.data.contractList) {
          commit('SET_TRACE_STATE_NUM', { type: 'add' })
          commit('SET_All_ContractList', data.data.contractList || [])
        }
      },

      async getMcpContracts({ commit }) {
        let res = await getMcpContractList()
        if (res.code == '200') {
          let tmp = res.data || []
          let tmp2 = []
          tmp.forEach(item => {
            if (item.businessLine == 10 || item.businessLine == 11) {
              tmp2 = tmp2.concat(item.symbolDTOList)
            }
          })
          tmp2.forEach(item => {
            delete item.contractLevelList
          })
          commit('SET_TRACE_STATE_NUM_MIX', { type: 'add' })
          commit('SET_ALL_MCP_CONTRACT_LIST', tmp2 || [])
        }
      },

      async getSupportCopyContractList({ commit }) {
        let res = await getCurrentSymbol()
        if (res.code == '200') {
          let tmp = res.data || []
          commit('SET_SUPPORT_COPY_CONTRACT_LIST', tmp)
        }
      },
      /**
       * 更新用户偏好设置
       * @param commit
       * @param state
       * @param setting
       */
      updateUserPreference({ commit, state }, setting) {
        // let flag = Object.keys(setting).every(key => state.userPreference.hasOwnProperty(key) && state.userPreference[key] !== setting[key])
        if (setting.hasOwnProperty('quantityIncludesDelegated')) {
          // 如果是"quantityIncludesDelegated"使用特殊接口变更
          return setDelegateFlag({
            delegateFlag: setting.quantityIncludesDelegated
          }).then(data => {
            if (data.code === '200') {
              commit('SET_USER_PREFERENCE', setting)
            } else {
              throw data.msg || 'error'
            }
          })
        } else {
          // 由于参数冲突，修改其他选项时，只能保持语言参数
          if (!('languageType' in setting)) {
            setting['languageType'] = state.userPreference.languageType
          }
          return setUserPreference(setting).then(data => {
            if (data.code === '00000') {
              commit('SET_USER_PREFERENCE', setting)
            } else {
              throw data.msg || 'error'
            }
          })
          // commit("SET_USER_PREFERENCE", setting);
        }
      },

      /**
       * 初始化获取用户偏好
       * @param commit
       * @param state
       */
      getUserPreference({ commit, state }) {
        getUserPreference().then(data => {
          if (data.code === '00000' && data.data) {
            commit('SET_USER_PREFERENCE', data.data)
          }
        })

        // getDelegateFlag().then(data => {
        //   if (data.code === '200') {
        //     if (data.data) {
        //       commit('SET_USER_PREFERENCE', { quantityIncludesDelegated: 1 })
        //     } else {
        //       commit('SET_USER_PREFERENCE', { quantityIncludesDelegated: 0 })
        //     }
        //   }
        // })
      },
      /**
       * 获取系统通知信息
       * @param commit
       * @returns {Promise<void>}
       */
      async getNoticeData({ commit }) {
        let params = { page: 1, pageSize: 3 };
        let data = await getSystemNotice(params);
        if (+data.code == 0 && data.data) commit('SET_NOTICE_DATA', data.data || {});
      },
      /**
       * 获取通知消息数量
       * @param commit
       * @returns {Promise<void>}
       */
      async getReadCount({ commit,state }) {
        getPushCount().then(res=>{
          if (+res.code == 0) {
            commit('SET_UNREAD_COUNT', res.data.total || 0)
          }
        });
      }
    },
    modules: {
      asset,
      trace,
      tracePro,
      spot: {
        namespaced: spot.namespaced,
        state: spot.state,
        mutations: spot.mutations,
        getters: spot.getters,
        actions: spot.actions
      },
      trade: {
        namespaced: true,
        state: TradeState,
        mutations: TradeMutations,
        getters: TradeGetters,
        actions: TradeActions
      },
      future: {
        namespaced: true,
        state: FutureState,
        mutations: FutureMutations,
        getters: FutureGetters,
        actions: FutureActions
      },
      newTrading: {
        namespaced: true,
        state: NewTradingState,
        mutations: NewTradingMutations,
        getters: NewTradingGetters,
        actions: NewTradingActions
      }
    }
  })

export default store
